/** @jsx jsx */
import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import {jsx} from '@emotion/react';

import {hexToRgbComponent} from '../../helpers/utils';
import {colors} from '../../styles/theme';


/*
 * Constants
 */
const canvasSize = 512;
const heightMapSize = 1024;


/*
 * Helpers
 */

/**
 * Get the distance of point x,y from the origin 0,0
 *
 * @param {number} x
 * @param {number} y
 * @returns {number}
 */
const distance = (x, y) => Math.sqrt(x * x + y * y);

/**
 * Get the first height map
 *
 * @returns {number[]}
 */
const getHeightMap1 = () => {
    const heightMap = [];
    const horizontalPlacementMultiplier = Math.random();
    const verticalPlacementMultiplier = Math.random();

    for (let u = 0; u < heightMapSize; u++) {
        for (let v = 0; v < heightMapSize; v++) {
            // index of coordinate in height map array
            const i = u * heightMapSize + v;

            // u,v are coordinates with origin at upper left corner
            // cx and cy are coordinates with origin at a random place the map
            const cx = u - heightMapSize * horizontalPlacementMultiplier;
            const cy = v - heightMapSize * verticalPlacementMultiplier;

            // distance from middle of map
            const d = distance(cx, cy);

            // stretching so we get the desired ripple density on our map
            const stretch = (3 * Math.PI) / (heightMapSize / 2);

            // wavy height value between -1 and 1
            const ripple = Math.sin(d * stretch);

            // wavy height value normalized to 0..1
            const normalized = (ripple + 1) / 2;

            // height map value 0..128, integer
            heightMap[i] = Math.floor(normalized * 128);
        }
    }

    return heightMap;
};

/**
 * Get the second height map
 *
 * @returns {number[]}
 */
const getHeightMap2 = () => {
    const heightMap = [];

    for (let u = 0; u < heightMapSize; u++) {
        for (let v = 0; v < heightMapSize; v++) {
            const i = u * heightMapSize + v;
            const cx = u - heightMapSize / 2;
            const cy = v - heightMapSize / 2;

            // skewed distance as input to chaos field calculation,
            // scaled for smoothness over map distance
            const d1 = distance(0.8 * cx, 1.3 * cy) * 0.022;
            const d2 = distance(1.35 * cx, 0.45 * cy) * 0.022;

            const sin = Math.sin(d1);
            const cos = Math.cos(d2);

            // height value between -2 and +2
            const h = sin + cos;

            // height value between 0..1
            const normalized = (h + 2) / 4;
            // height value between 0..180, integer
            heightMap[i] = Math.floor(normalized * 180);
        }
    }

    return heightMap;
};


/**
 * Get the updated data to draw on the canvas
 *
 * This method returns a function that takes a time parameter and returns the updated ImageData object.
 *
 * @param {ImageData} image
 * @param {Object} heightMap1
 * @param {Object} heightMap2
 * @param {Object} colorRGB
 * @return {function}
 */
const UpdatedImageData = (image, heightMap1, heightMap2, colorRGB) => time => {
    // Move the height maps
    const dx1 = Math.floor((((Math.cos(time * 0.0002 + 0.4 + Math.PI) + 1) / 2) * canvasSize) / 4);
    const dy1 = Math.floor((((Math.cos(time * 0.0003 - 0.1) + 1) / 2) * canvasSize) / 4);
    const dx2 = Math.floor((((Math.cos(time * -0.0002 + 1.2) + 1) / 2) * canvasSize) / 4);
    const dy2 = Math.floor((((Math.cos(time * -0.0003 - 0.8 + Math.PI) + 1) / 2) * canvasSize) / 4);

    // Update the image data
    for (let u = 0; u < canvasSize; u++) {
        for (let v = 0; v < canvasSize; v++) {
            // indexes into height maps for pixel
            const i = (u + dy1) * heightMapSize + (v + dx1);
            const k = (u + dy2) * heightMapSize + (v + dx2);

            // index for pixel in image data
            // remember it's 4 bytes per pixel
            const j = u * canvasSize * 4 + v * 4;

            // height value of 0..255
            const h = heightMap1[i] + heightMap2[k];

            // set pixel data
            /* eslint-disable no-param-reassign */
            image.data[j] = colorRGB.r;
            image.data[j + 1] = colorRGB.g;
            image.data[j + 2] = colorRGB.b;
            image.data[j + 3] = h;
            /* eslint-enable no-param-reassign */
        }
    }

    return image;
};


/*
 * Private Elements
 */
const StyledAnimatedBackground = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;

    .animated-background-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: ${colors.darkPurple};
    }

    .animated-background-content {
        position: relative;
        width: 100%;
        height: 100%;
    }
`;

const AnimatedCanvas = styled.canvas`
    background: ${colors.darkPurple} linear-gradient(to bottom, ${colors.transparent}, ${colors.black});
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    &.contrast {
        background: ${colors.darkPurple};
    }
`;


/*
 * Public Elements
 */
const AnimatedBackground = ({className = '', contrast = false, children}) => {
    const ref = useRef(null);

    // Shamelessly based on https://towardsdatascience.com/fun-with-html-canvas-lets-make-lava-lamp-plasma-e4b0d89fe778
    useEffect(() => {
        const color = contrast ? colors.purple : colors.black;
        const colorRGB = hexToRgbComponent(color);
        const canvas = ref.current;

        canvas.width = canvasSize;
        canvas.height = canvasSize;

        const canvasContext = ref.current.getContext('2d');
        const image = canvasContext.createImageData(canvasSize, canvasSize);

        const heightMap1 = getHeightMap1();
        const heightMap2 = getHeightMap2();
        const getUpdatedImageData = UpdatedImageData(image, heightMap1, heightMap2, colorRGB);

        const tick = async time => {
            canvasContext.putImageData(getUpdatedImageData(time), 0, 0);
            requestAnimationFrame(tick);
        };

        requestAnimationFrame(tick);
    }, [contrast]);

    return (
        <StyledAnimatedBackground className={`animated-background ${className}`}>
            <div className="animated-background-wrapper">
                <AnimatedCanvas ref={ref} className={contrast ? 'contrast' : ''} />
            </div>
            <div className="animated-background-content">
                {children}
            </div>
        </StyledAnimatedBackground>
    );
};

AnimatedBackground.propTypes = {
    className: PropTypes.string,
    contrast: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

AnimatedBackground.defaultProps = {
    className: '',
    contrast: false,
};


/*
 * Exports
 */
export default AnimatedBackground;
